import React from "react"
import PropTypes from "prop-types"

import Img from "gatsby-image"
import styled from "styled-components"

const GalleryContainer = styled.div`
  position: absolute;

  p {
    position: fixed !important;
    z-index: 1;
    text-align: center;
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0px;
  }

  .gallery-container {
    margin-left: 300px;
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
  }

  @media (max-width: ${props => props.theme.sizes.mobile}) {
    .gallery-container {
      flex-direction: column;
      margin-left: 0;
    }
    p {
      position: absolute !important;
    }
  }
`
const Image = styled(Img)`
  width: calc(${props => props.aspectRatio} * 80vh);
  height: 80vh;
  margin-right: 1rem;

  @media (max-width: ${props => props.theme.sizes.mobile}) {
    width: 100vw;
    height: auto;
    margin-right: 0;
  }
`

const Gallery = ({ images, title }) => {
  const photos = images.map(({ fluid }) => {
    return <Image aspectRatio={fluid.aspectRatio} fluid={fluid} />
  })
  return (
    <GalleryContainer>
      <p>{title}</p>
      <div className="gallery-container">{photos}</div>
    </GalleryContainer>
  )
}

export default Gallery

Gallery.defaultProps = {
  images: [
    {
      fluid: {
        base64: "",
        aspectRatio: "",
        src: "",
        srcSet: "",
      },
    },
  ],
}

Gallery.propTypes = {
  images: PropTypes.arrayOf({
    fluid: PropTypes.shape({
      base64: PropTypes.string,
      aspectRatio: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),
  }),
  title: PropTypes.string.isRequired,
}
