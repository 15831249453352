/* eslint-disable react/prop-types */
import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"

import SEO from "../components/seo"
import Gallery from "../components/gallery"

const PhotosPage = ({ data, location }) => {
  const { title, photos } = data.contentfulSub || data.contentfulMain

  let allPhotos
  if (data.contentfulSub === null && data.contentfulMain.sub !== null) {
    allPhotos = data.contentfulMain.sub.reduce((acc, photo) => {
      photo.photos.forEach(pic => {
        acc.push(pic)
      })
      return acc
    }, [])
  }

  const pictures = photos || allPhotos

  return (
    <Fragment>
      <SEO title={title} />
      <Gallery images={pictures} pathname={location.pathname} title={title} />
    </Fragment>
  )
}

PhotosPage.propTypes = {
  data: PropTypes.shape({
    contenfulMain: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      photos: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          fluid: PropTypes.shape({
            src: PropTypes.string,
            srcSet: PropTypes.string,
            base64: PropTypes.string,
            aspectRatio: PropTypes.string,
          }),
        })
      ),
      sub: PropTypes.arrayOf(
        PropTypes.shape({
          photos: PropTypes.shape({
            title: PropTypes.string,
            fluid: PropTypes.shape({
              src: PropTypes.string,
              srcSet: PropTypes.string,
              base64: PropTypes.string,
              aspectRatio: PropTypes.string,
            }),
          }),
        })
      ),
    }),
    contenfulSub: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      photos: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          fluid: PropTypes.shape({
            src: PropTypes.string,
            srcSet: PropTypes.string,
            base64: PropTypes.string,
            aspectRatio: PropTypes.string,
          }),
        })
      ),
      main: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default PhotosPage

export const photosQuery = graphql`
  query($id: String!) {
    contentfulMain(id: { eq: $id }) {
      title
      slug
      photos {
        title
        fluid {
          base64
          aspectRatio
          src
          srcSet
        }
      }
      sub {
        photos {
          fluid {
            base64
            aspectRatio
            src
            srcSet
          }
        }
      }
    }
    contentfulSub(id: { eq: $id }) {
      title
      slug
      main {
        slug
      }
      photos {
        title
        fluid {
          base64
          aspectRatio
          src
          srcSet
        }
      }
    }
  }
`
